require("./bootstrap");

import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import translate from "./translate";

// if (process.env.NODE_ENV == "production") {
//     const analytics = Analytics({
//         app: "5forummw",
//         plugins: [
//             googleAnalytics({
//                 measurementIds: ["G-B2XCD3R8N9"],
//             }),
//         ],
//     });
//     /* Track a page view */
//     analytics.page();
// }

function checkPhoneField() {
    const country = COUNTRIES.find(
        (el) =>
            el?.name ==
            $("select[name=country]").children("option:selected").val()
    );
    if (country) {
        $("input[name=phone]").val("+" + country?.calling_code);
        $("input[name=mobile_phone]").val("+" + country?.calling_code);
    }
}
function checkWorkshops(e) {
    const totalChecked = $("input[name='workshops[]']").filter(
        ":checked"
    ).length;
    if (totalChecked > 3) {
        $(e.target).prop("checked", false);
    }
}
function calculateDateDifference() {
    const arrivalDate = new Date(
        $("input[name='expected_arrival_date']").val()
    );
    const departureDate = new Date(
        $("input[name='expected_departure_date']").val()
    );

    if (departureDate <= arrivalDate) {
        $("input[name='expected_departure_date']").val("");
        return null;
    } else {
        var timeDiff = departureDate - arrivalDate;
        var dayDiff = timeDiff / (1000 * 3600 * 24);
        return dayDiff;
    }
}
function updateHotel() {
    const hotel = $("input[name='hotel_offers[]']:checked").data('hotel');
    $("input[name='hotel']").val(hotel || '')
}
function checkHotelOffers(e) {
    $("input[name='hotel_offers[]']").attr('disabled', false);
    if (!e.target.checked)
        return
    const hotel = $(e.target).data('hotel');
    const type = $(e.target).data('type');
    $(`.form-check[data-hotel="${hotel}"]`)
        .children(`input[data-type!="${type}"]`)
        .attr('disabled', true)
}
function calculateTotalToPay() {
    let total = $("input[name=fee_type]:checked").data("price") || 0;
    $("#fee-card-alert").hide()
    $("#fee-card-alert").html("")
    $("#total_to_pay").val(total + " DT");
    $("input[name='hotel_offers[]']:checked")
        .each(function (i, el) {
            total += parseFloat(el.dataset.price) || 0
        })
    $("input[name='transport[]']:checked")
        .each(function (i, el) {
            total += parseFloat(el.dataset.price) || 0
        })
    $("input[name='extra[]']:checked")
        .each(function (i, el) {
            total += parseFloat(el.dataset.price) || 0
        })
    $("#total_to_pay").val(`${total} DT`);
    updateHotel()
}

$(function () {
    calculateTotalToPay()
    $("select[name=country]").change(function (e) {
        checkPhoneField();
    });
    $("input[name='workshops[]']").click(function (e) {
        checkWorkshops(e);
    });
    $("input[name='hotel_offers[]']").change(function (e) {
        checkHotelOffers(e);
        calculateTotalToPay();
    });
    $("input[name='transport[]']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[name='extra[]']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[type='date']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[name='fee_type']").change(function (e) {
        calculateTotalToPay();
    });
});
